import React, { Fragment } from 'react';

const NonDiscriminationStament = (props) => {
  const {data} = props;
  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">USDA Nondiscrimination Statement</h4>
      <div className="agreement-div scroll">
      <p>
        Date {data.submittedAt ? (new Date(data.submittedAt).toLocaleDateString('en-US')):(new Date()).toLocaleDateString('en-US')}
      </p>
      <h5 className="black-headline">USDA Nondiscrimination Statement</h5>
      <p>
        In accordance with Federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, the USDA, its Agencies, offices, and employees, and institutions participating in or administering USDA programs are prohibited from discriminating based on race, color, national origin, sex, disability, age, or reprisal or retaliation for prior civil rights activity in any program or activity conducted or funded by USDA.
      </p>
      <p>
        Persons with disabilities who require alternative means of communication for program information (e.g. Braille, large print, audiotape, American Sign Language, etc.), should contact the Agency (State or local) where they applied for benefits. Individuals who are deaf, hard of hearing or have speech disabilities may contact USDA through the Federal Relay Service at (800) 877-8339. Additionally, program information may be made available in languages other than English.
      </p>
      <p>
        To file a program complaint of discrimination, complete the USDA Program Discrimination Complaint Form, (AD-3027) found online at: https://www.usda.gov/oascr/how-to-file-a- programdiscrimination-complaint, and at any USDA office, or write a letter addressed to USDA and provide in the letter all of the information requested in the form. To request a copy of the complaint form, call (866) 632-9992. Submit your completed form or letter to USDA by:
      </p>
      <p>
        <b>(1) mail: U.S.</b> Department of Agriculture
      </p>
      <p>
        Office of the Assistant Secretary for Civil Rights
      </p>
      <p>
        1400 Independence Avenue, SW Washington, D.C. 20250-9410;
      </p>
      <p>
        <b>(2) fax.</b> (202) 690-7442; or
      </p>
      <p>
        <b>(3) email:</b> program.intake@usda.gov. This institution is an equal opportunity provider.
      </p>
      <p>
        *FOR PARENT AND CHILD ACCOUNTS LISTED IN THIS AGREEMENT: Child account distributions must be approved by Outreach Logistics Senior Manager and should be: consistent (minimum of one (1) distribution per month), long-term (12 month commitment or longer), and governed by a Memorandum of Understanding (MOU), a copy of which must be provided to School Programs.
      </p>
      </div>
    </Fragment>
  );
};

export default NonDiscriminationStament;
