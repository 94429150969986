/* eslint-disable no-console */
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { graphql, compose } from 'react-apollo';
import { get, indexOf } from 'lodash';
import classnames from 'classnames';
import Loading from '../Loading';
import getErrorMessage from '../../helpers/getErrorMessage';
import removeTypename from '../../helpers/removeTypename';
import { setUserAction } from '../../redux';
import StepOneForm from './StepOneForm';
import StepTwoForm from './StepTwoForm';
import StepThreeForm from './StepThreeForm';
import ApplicationStatusPending from '../ApplicationStatusPending';
import ProgressBar from '../ProgressBar';
import {
  educatorUserQuery,
  applicationBackpackBuddyQuery,
  configBackpackBuddyQuery,
  saveApplicationStepOneMutation,
  saveApplicationStepTwoMutation,
  saveApplicationStepThreeMutation,
  userApplicationsBackpackBuddyQuery,
  programTermsQuery,
} from '../../apollo';
import withProgramsLayout from '../../helpers/withProgramsLayout';
import { DEFAULT_TERM_YEAR } from '../../config';

class ApplicationBackpackBuddy extends Component {
  steps = {
    '/backpack-buddy/application/step-1': {
      step: 1,
      component: StepOneForm,
    },
    '/backpack-buddy/application/step-2': {
      step: 2,
      component: StepTwoForm,
    },
    '/backpack-buddy/application/step-3': {
      step: 3,
      component: StepThreeForm,
    },
    '/backpack-buddy/application/status': {
      step: 4,
      component: ApplicationStatusPending,
    },
  }

  getNextStep = (applicationBackpackBuddyId) => {
    const { match } = this.props;
    const applicationSteps = Object.keys(this.steps);
    const currentIndex = indexOf(applicationSteps, `/backpack-buddy/application/${match.params.step}`);
    const stepsToCheck = applicationSteps.slice(currentIndex, applicationSteps.length + 1);
    let nextStep = stepsToCheck.find(step => (
      step !== `/backpack-buddy/application/${match.params.step}`
    ));

    if (applicationBackpackBuddyId) {
      nextStep = `${nextStep}/${applicationBackpackBuddyId}`;
    }
    return nextStep;
  }

  saveApplication = async ({
    draft,
    applicationBackpackBuddyId,
    new: isNew,
    termId,
    school,
    emailSecondaryCoordinator,
    certififedVerification,
    organizationType,
    nonProfitOrganizations,
    numFreeLunch,
    numReducedLunch,
    numTotalEnrollment,
    willHelpMediaEvents,
    willDistributeInfo,
    programInterests,
    agreeToProgramPurpose,
    agreeToProgramResponsibilities,
    agreeNondiscrimination,
    nameSignFirst,
    nameSignSecond,
    nameSignThird
  }) => {
    const {
      dispatch,
      client: { mutate, query },
      match,
    } = this.props;

    const { step } = this.steps[`/backpack-buddy/application/${match.params.step}`];
    let mutation;
    let response;
    let variables;

    switch (step) {
      case 1:
        mutation = saveApplicationStepOneMutation;
        response = 'saveApplicationStepOne';
        variables = {
          draft,
          applicationBackpackBuddyId,
          new: isNew,
          termId,
          organizationType,
          nonProfitOrganizations,
          school: {
            id: school.id,
            schoolId: school.schoolId,
            name: school.name,
            gradesServed: school.gradesServed,
            address1: school.address1,
            address2: school.address2,
            city: school.city,
            state: school.state,
            zip: school.zip,
            county: school.county,
            districtId: school.districtId,
            type: school.type,
            teamMembersBackpackBuddy: school.teamMembersBackpackBuddy,
          },
          emailSecondaryCoordinator,
        };
        break;
      case 2:
        mutation = saveApplicationStepTwoMutation;
        response = 'saveApplicationStepTwo';
        variables = {
          draft,
          applicationBackpackBuddyId,
          numFreeLunch,
          numReducedLunch,
          numTotalEnrollment,
          willHelpMediaEvents,
          willDistributeInfo,
          programInterests,
          certififedVerification,
        };
        break;
      case 3:
        mutation = saveApplicationStepThreeMutation;
        response = 'saveApplicationStepThree';
        variables = {
          draft,
          applicationBackpackBuddyId,
          agreeToProgramPurpose,
          agreeToProgramResponsibilities,
          agreeNondiscrimination,
          nameSignFirst,
          nameSignSecond,
          nameSignThird
        };
        break;
      default:
        break;
    }

    try {
      const { data } = await mutate({
        mutation,
        variables: { input: { ...removeTypename(variables) } },
        refetchQueries: [{
          query: userApplicationsBackpackBuddyQuery,
        }],
      });

      const { data: { educatorUser } } = await query({
        query: educatorUserQuery,
        fetchPolicy: 'network-only',
      });

      if (data[response] && educatorUser) {
        if (!draft) {
          dispatch(setUserAction(educatorUser));
          dispatch(push(this.getNextStep(data[response].applicationBackpackBuddyId)));
          toast('Save Successful');
        } else {
          dispatch(setUserAction(educatorUser));
          dispatch(push('/'));
          toast('Draft Saved');
        }
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage) toast(errorMessage);
      throw (error);
    }
  };

  saveAsDraft = async (values) => {
    try {
      await this.saveApplication({
        draft: true,
        ...values,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage) toast(errorMessage);
      throw (error);
    }
  };

  render() {
    const {
      match,
      location,
      data,
      configBackpackBuddy,
      programTermsData,
      user,
      adminView,
      program: {
        name,
        programUrl,
      },
    } = this.props;

    if (
      (data && data.loading)
      || configBackpackBuddy.loading
      || programTermsData.loading
    ) {
      return <Loading />;
    }

    //
    // Backpack Buddy Application Redirect Logic
    //
    if (adminView) {
      if (
        (data && data.error)
        || (data && !data.applicationBackpackBuddy)
        || configBackpackBuddy.error
      ) {
        return <Redirect to={`${programUrl}/schools`} />;
      }
      if (
        (data && data.applicationBackpackBuddy && match.params.step === 'status')
      ) {
        return <Redirect to={`${programUrl}/schools`} />;
      }
    } else {
      // If error loading application or application config re-route user
      if (
        (data && data.error)
        || (data && !data.applicationBackpackBuddy)
        || configBackpackBuddy.error
      ) {
        return <Redirect to="/login" />;
      }
      // If attempt to view further step before there is data, route to first step
      if (match.params.step && match.params.step !== 'step-1' && !data) {
        return <Redirect to={`${programUrl}/application/step-1`} />;
      }
      // If no associated application route to the start page
      if (
        user.applicationBackpackBuddyRequired
        && !user.applicationBackpackBuddyRequiredId
        && !match.params.step
        && !data
      ) {
        return <Redirect to={`${programUrl}/start`} />;
      }
      // If required to fill out application route to the first step
      if (
        user.applicationBackpackBuddyRequired
        && user.applicationBackpackBuddyRequiredId
        && !data
      ) {
        return <Redirect to={`${programUrl}/application/step-1/${user.applicationBackpackBuddyRequiredId}`} />;
      }
      // If attempt to view an input step of the application while
      // it's pending approval, route to the status page
      if (
        (data
          && data.applicationBackpackBuddy
          && data.applicationBackpackBuddy.status === 'PENDING_PRINCIPAL'
          && match.params.step !== 'status')
      ) {
        return <Redirect to={`${programUrl}/application/status/${match.params.id}`} />;
      }
      // If attempt to view the status step of the application while
      // it's in progress, route to the first step
      if (
        (data
          && data.applicationBackpackBuddy
          && data.applicationBackpackBuddy.status === 'IN_PROGRESS'
          && match.params.step === 'status')
      ) {
        return <Redirect to={`${programUrl}/application/step-1/${user.applicationBackpackBuddyRequiredId}`} />;
      }
    }

    const activeStep = get(this.steps, [`${programUrl}/application/${match.params.step}`]);

    if (!activeStep) return <Redirect to="/login" />;
    const { component: ApplicationStep, step } = activeStep;
    const { schoolEnabled, summerEnabled } = configBackpackBuddy.configBackpackBuddy;
    const params = new URLSearchParams(location.search);
    let termYear = params.get('termYear') || DEFAULT_TERM_YEAR;
    if (data && data.applicationBackpackBuddy && data.applicationBackpackBuddy.term) {
      termYear = data.applicationBackpackBuddy.term.year;
    }

    const termsForYear = programTermsData.programTerms.filter(({ year }) => year === termYear);
    const termsAvailable = (configBackpackBuddy && termsForYear && Boolean(termsForYear.length) && (schoolEnabled || summerEnabled));
    const readOnly = (data && data.applicationBackpackBuddy && (data.applicationBackpackBuddy.status === 'APPROVED')) || adminView || !termsAvailable;
    
    console.log('Program Terms');
    console.log(programTermsData.programTerms);

    return (
      <Fragment>
        <section className={classnames({ 'section-content': activeStep.step !== 4 }, { 'section-begin-application': activeStep.step === 4 })}>
          <div className="_1300-container">
            <div className={classnames({ 'form-block-4 w-form': activeStep.step !== 4 }, { 'start-flex': activeStep.step === 4 })}>
              <ProgressBar step={step} programName={name} />
              <ApplicationStep
                {...this.props}
                save={this.saveApplication}
                saveAsDraft={this.saveAsDraft}
                readOnly={readOnly}
                termsAvailable={termsAvailable}
                termsForYear={termsForYear}
                step={step}
              />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('applicationForm');
  const currentApplication = get(ownProps.data, 'applicationBackpackBuddy', {
    emailSecondaryCoordinator: false,
    school: {
      teamMembersBackpackBuddy: [],
    },
  });

  return {
    adminView: state.user.type === 'ADMIN',
    newTeamMember: selector(state, 'newTeamMember'),
    formSyncErrors: getFormSyncErrors('applicationForm')(state),
    user: state.user,
    initialValues: {
      ...currentApplication,
      readProgramAgreement: false,
    },
  };
};

const withData = ComponentToWrap => (
  (otherProps) => {
    const WithData = compose(
      graphql(applicationBackpackBuddyQuery, {
        name: 'data',
        skip: ({ match }) => !match.params.id,
        options: ({ match }) => ({
          variables: {
            applicationBackpackBuddyId: match.params.id,
          },
          fetchPolicy: 'network-only',
        }),
      }),
      graphql(configBackpackBuddyQuery, {
        name: 'configBackpackBuddy',
      }),
      graphql(programTermsQuery, {
        name: 'programTermsData',
        options: ({ program: { name } }) => ({
          variables: {
            programName: name,
          },
          fetchPolicy: 'network-only',
        }),
      }),
      connect(mapStateToProps),
      reduxForm({
        form: 'applicationForm',
        enableReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
      }),
    )(ComponentToWrap);

    return <WithData {...otherProps} />;
  }
);

ApplicationBackpackBuddy.propTypes = {
  adminView: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  configBackpackBuddy: PropTypes.object.isRequired,
  data: PropTypes.object,
  programTermsData: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};

ApplicationBackpackBuddy.defaultProps = {
  data: null,
};

export default withProgramsLayout(withData(ApplicationBackpackBuddy));
