/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ProgramAgreement = () => {

  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">The Agreement</h4>
      <div className="agreement-div scroll">
        <h5 className="black-headline center">Exhibit A: Backpack Buddy</h5>
        <h5 className="black-headline">Program Description</h5>
        <p>
          Backpack Buddy is a program that helps alleviate child hunger by closing the weekend gap for chronically hungry children. Better known as children who face food insecurity or a household crisis. Every Friday, students participating in this program will receive a nutritious, children-friendly food sack to take home and be back to school on Monday, eager and ready to learn. Backpack Buddy welcomes elementary, middle, and high schools to participate.
        </p>
        <h5 className="black-headline">Program Details – School Programs Partner’s Responsibilities</h5>
        <ul className="agreement-text w-list-unstyled">
        <li className="agreement-list-item sub"><p><span className="line-item">1.</span> Identify chronically hungry children in your school(s) using the guidelines specified in the Student Referral Forms, as indicated in the Food Insecurity training and Backpack Buddy Information Packet.</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">2.</span> Ensure that all students participants have a signed permission slip on Claromentis. All Backpack Buddy records should be stored for a minimum of three years. *Scanning permission slips to the Backpack Buddy staff will not be accepted.</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">3.</span> Participating students must meet income eligibility with either receiving free and/or reduced lunch at their school or have a letter of extreme circumstances or economic hardship(s) written by a parent/guardian or School Coordinator.</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">4.</span> Identify any food allergies that the enrolled student may have (peanuts, milk, etc.). Flag their name so that those items will not be distributed to them once sent to the school. Contact your assigned Backpack Buddy Specialist for specific food allergy.</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">5.</span> Distribute food sacks each week (on the last instructional day of the week). unless otherwise specified due to holidays or school closures) to children with completed permission forms on file.</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">6.</span> Provide food sacks a minimum of three (3) times a month while the program is in operation.</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">7.</span> Keep accurate records. Prepare, submit, and adhere to the electronic quarterly report system operated by HFB by the 1st of the next quarter.</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">-</span> 1st QTR: July – September</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">-</span> 2nd QTR: October – December</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">-</span> 3rd QTR: January – March</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">-</span> 4th QTR: April – June</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">8.</span> Ensure School Partner staff and volunteers with direct repetitive contact with children pass a national background check, as indicated in the Backpack Buddy Informational Packet.</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">9.</span> Only increase allotment number every quarter if needed.</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">-</span> 1st QTR: July – September</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">-</span> 2nd QTR: October – December</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">-</span> 3rd QTR: January – March</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">-</span> 4th QTR: April – June</p></li>        
        <li className="agreement-list-item sub"><p><span className="line-item">10.</span> Receive food from the HFB to help provide food to chronically hungry children over the weekend. I understand that the food we receive for the Backpack Buddy program can only be used for that purpose. I understand that the food and backpacks cannot be sold, used for other school programs, used for fundraisers, given to staff, or used for any other purpose other than to provide food to chronically hungry children. Food sack items may not be removed, replaced, or added unless approved by the HFB. Failure to comply with the program requirements will result in the loss of the Backpack Buddy program at School Partner.</p></li>
        <li className="agreement-list-item sub"><p><span className="line-item">11.</span> For any questions that you may have about the program please contact the Backpack Buddy staff at backpackbuddy@houstonfoodbank.org.</p></li>

        </ul>
      </div>
    </Fragment>
  );
};

ProgramAgreement.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default ProgramAgreement;
