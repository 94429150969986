import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, getFormSyncErrors, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Redirect } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { get, indexOf } from 'lodash';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import ApplicationSchoolMarketStepOne from './ApplicationSchoolMarketStepOne';
import Loading from '../Loading';
import getErrorMessage from '../../helpers/getErrorMessage';
import withProgramsLayout from '../../helpers/withProgramsLayout';
import removeTypename from '../../helpers/removeTypename';
import { setUserAction } from '../../redux';
import ApplicationStatusPending from '../ApplicationStatusPending';
import { DEFAULT_TERM_YEAR } from '../../config';
import {
  educatorUserQuery,
  configSchoolMarketQuery,
  saveSchoolMarketApplicationMutation,
  applicationSchoolMarketQuery,
  programTermsQuery,
  userApplicationsSchoolMarketQuery,
} from '../../apollo';

class ApplicationSchoolMarket extends Component {
  steps = {
    '/school-market/application/step-1': {
      step: 1,
      component: ApplicationSchoolMarketStepOne,
    },
    '/school-market/application/status': {
      step: 2,
      component: ApplicationStatusPending,
    },
  }

  getNextStep = (applicationSchoolMarketId) => {
    const { match } = this.props;
    const applicationSteps = Object.keys(this.steps);
    const currentIndex = indexOf(applicationSteps, `/school-market/application/${match.params.step}`);
    const stepsToCheck = applicationSteps.slice(currentIndex, applicationSteps.length + 1);
    let nextStep = stepsToCheck.find(step => (
      step !== `/school-market/application/${match.params.step}`
    ));

    if (applicationSchoolMarketId) {
      nextStep = `${nextStep}/${applicationSchoolMarketId}`;
    }
    return nextStep;
  }

  saveApplication = async ({
    draft,
    applicationSchoolMarketId,
    new: isNew,
    termId,
    school,
    numFreeLunch,
    numReducedLunch,
    numTotalEnrollment,
    marketType,
    operatingFrequency,
    preferredDistributionDay,
    preferredDistributionTime,
    secondaryPreferredDistributionDay,
    secondaryPreferredDistributionTime,
    certififedVerification,
    agreeToProgramPurpose,
    agreeToProgramResponsibilities,
    agreeNondiscrimination,
    organizationType,
    nonProfitOrganizations,
    nameSignFirst,
    nameSignSecond,
    nameSignThird
  }) => {
    const {
      dispatch,
      client: { mutate, query },
    } = this.props;

    const variables = {
      draft,
      applicationSchoolMarketId,
      new: isNew,
      termId,
      organizationType,
      nonProfitOrganizations,
      school: {
        id: school.id,
        schoolId: school.schoolId,
        name: school.name,
        gradesServed: school.gradesServed,
        address1: school.address1,
        address2: school.address2,
        city: school.city,
        state: school.state,
        zip: school.zip,
        county: school.county,
        districtId: school.districtId,
        type: school.type,
        primaryPointOfContact: {
          ...school.primaryPointOfContact,
          type: 'PRIMARY_POINT_OF_CONTACT',
        },
        principal: {
          ...school.principal,
          type: 'PRINCIPAL',
        },
      },
      numFreeLunch,
      numReducedLunch,
      numTotalEnrollment,
      marketType,
      operatingFrequency,
      preferredDistributionDay,
      preferredDistributionTime,
      secondaryPreferredDistributionDay,
      secondaryPreferredDistributionTime,
      agreeToProgramPurpose,
      agreeToProgramResponsibilities,
      agreeNondiscrimination,
      nameSignFirst,
      nameSignSecond,
      nameSignThird,
      certififedVerification,
    };
    try {
      const { data } = await mutate({
        mutation: saveSchoolMarketApplicationMutation,
        variables: { input: { ...removeTypename(variables) } },
        refetchQueries: [{
          query: userApplicationsSchoolMarketQuery,
        }],
      });

      const { data: { educatorUser } } = await query({
        query: educatorUserQuery,
        fetchPolicy: 'network-only',
      });

      if (data.saveSchoolMarketApplication && educatorUser) {
        if (!draft) {
          dispatch(setUserAction(educatorUser));
          dispatch(push(this.getNextStep(data.saveSchoolMarketApplication.applicationBackpackBuddyId)));
          toast('Save Successful');
        } else {
          dispatch(setUserAction(educatorUser));
          dispatch(push('/'));
          toast('Draft Saved');
        }
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage) toast(errorMessage);
      throw (error);
    }
  };

  saveAsDraft = async (values) => {
    try {
      await this.saveApplication({
        draft: true,
        ...values,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage) toast(errorMessage);
      throw (error);
    }
  };

  render() {
    const {
      match,
      location,
      data,
      configSchoolMarket,
      programTermsData,
      user,
      adminView,
      program: {
        programUrl,
      },
    } = this.props;

    if (
      (data && data.loading)
      || configSchoolMarket.loading
      || programTermsData.loading
    ) return <Loading />;
  
    //
    // School Market Application Redirect Logic
    //
    if (adminView) {
      if (
        (data && data.error)
        || (data && !data.applicationSchoolMarket)
        || configSchoolMarket.error
      ) {
        return <Redirect to={`${programUrl}/schools`} />;
      }
      if (
        (data && data.applicationSchoolMarket && match.params.step === 'status')
      ) {
        return <Redirect to={`${programUrl}/schools`} />;
      }
    } else {
      // If error loading application or application config re-route user
      if (
        (data && data.error)
        || (data && !data.applicationSchoolMarket)
        || configSchoolMarket.error
      ) {
        return <Redirect to="/login" />;
      }
      // If no associated application route to the start page
      if (
        user.applicationSchoolMarketRequired
        && !user.applicationSchoolMarketRequiredId
        && !match.params.step
        && !data
      ) {
        return <Redirect to={`${programUrl}/start`} />;
      }
      // If required to fill out application route to the first step
      if (
        user.applicationSchoolMarketRequired
        && user.applicationSchoolMarketRequiredId
        && !data
      ) {
        return <Redirect to={`${programUrl}/application/step-1/${user.applicationSchoolMarketRequiredId}`} />;
      }
      // If attempt to view an input step of the application while
      // it's pending approval, route to the status page
      if (
        (data
          && data.applicationSchoolMarket
          && data.applicationSchoolMarket.status === 'PENDING_PRINCIPAL'
          && match.params.step !== 'status')
      ) {
        return <Redirect to={`${programUrl}/application/status/${match.params.id}`} />;
      }
      // If attempt to view the status step of the application while
      // it's in progress, route to the first step
      if (
        (data
          && data.applicationSchoolMarket
          && data.applicationSchoolMarket.status === 'IN_PROGRESS'
          && match.params.step === 'status')
      ) {
        return <Redirect to={`${programUrl}/application/step-1/${user.applicationSchoolMarketRequiredId}`} />;
      }
    }

    const activeStep = get(this.steps, [`${programUrl}/application/${match.params.step}`]);

    if (!activeStep) return <Redirect to="/login" />;
    const { component: ApplicationStep, step } = activeStep;
    const { schoolEnabled, summerEnabled } = configSchoolMarket.configSchoolMarket;
    const params = new URLSearchParams(location.search);
    let termYear = params.get('termYear') || DEFAULT_TERM_YEAR;
    if (data && data.applicationSchoolMarket && data.applicationSchoolMarket.term) {
      termYear = data.applicationSchoolMarket.term.year;
    }
    const termsForYear = programTermsData.programTerms.filter(({ year }) => year === termYear);
    const termsAvailable = (configSchoolMarket && termsForYear && Boolean(termsForYear.length) && (schoolEnabled || summerEnabled));
    const readOnly = (data && data.applicationSchoolMarket && (data.applicationSchoolMarket.status === 'APPROVED')) || adminView || !termsAvailable;
    const appTermYear = get(data, 'applicationSchoolMarket.term.year', termYear);

    return (
      <Fragment>
        <section className={classnames({ 'section-content': activeStep.step !== 2 }, { 'section-begin-application': activeStep.step === 2 })}>
          <div className="_1300-container">
            <div className={classnames({ 'program-form-block w-form': activeStep.step !== 2 }, { 'start-flex': activeStep.step === 2 })}>
              {activeStep.step === 1 && <h2>{`School Market Program Application ${appTermYear} `}</h2>}
              <ApplicationStep
                {...this.props}
                submit={this.saveApplication}
                saveAsDraft={this.saveAsDraft}
                exit={this.exitApplication}
                readOnly={readOnly}
                termsAvailable={termsAvailable}
                termsForYear={termsForYear}
                termYear={appTermYear}
                step={step}
              />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const currentApplication = get(ownProps.data, 'applicationSchoolMarket');
  const selector = formValueSelector('applicationForm');
  return {
    adminView: state.user.type === 'ADMIN',
    formSyncErrors: getFormSyncErrors('applicationForm')(state),
    user: state.user,
    propData: {
      applicationSchoolMarket: {
        organizationType: selector(state, 'organizationType'),
        nonProfitOrganizations: selector(state, 'nonProfitOrganizations'),
        school: selector(state, 'school'),
        educator: state.user
      }
    },
    initialValues: {
      ...currentApplication,
    },
  };
};

const withData = ComponentToWrap => (
  (otherProps) => {
    const WithData = compose(
      graphql(applicationSchoolMarketQuery, {
        name: 'data',
        skip: ({ match }) => !match.params.id,
        options: ({ match }) => ({
          variables: {
            applicationSchoolMarketId: match.params.id,
          },
          fetchPolicy: 'network-only',
        }),
      }),
      graphql(configSchoolMarketQuery, {
        name: 'configSchoolMarket',
      }),
      graphql(programTermsQuery, {
        name: 'programTermsData',
        options: ({ program: { name } }) => ({
          variables: {
            programName: name,
          },
          fetchPolicy: 'network-only',
        }),
      }),
      connect(mapStateToProps),
      reduxForm({
        form: 'applicationForm',
        enableReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
      }),
    )(ComponentToWrap);

    return <WithData {...otherProps} />;
  }
);

ApplicationSchoolMarket.propTypes = {
  adminView: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  configSchoolMarket: PropTypes.object.isRequired,
  data: PropTypes.object,
  programTermsData: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};

ApplicationSchoolMarket.defaultProps = {
  data: null,
};

export default withProgramsLayout(withData(ApplicationSchoolMarket));