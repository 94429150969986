import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import TextField from '../TextField';
import RadioButtonGroup from '../RadioButtonGroup';
import CheckboxGroup from '../CheckboxGroup';
import TextArea from '../TextArea';
import Checkbox from '../Checkbox';
import SiteSelectInput from '../SiteSelectInput';
import ApplicationActions from '../ApplicationActions';
import { allSitesQuery } from '../../apollo';
import kidsCafeServiceInterests from '../../constants/kidsCafeServiceInterests';
import BackgroundVerification from '../BackgroundVerification';
import KidsCafeProgramAgreement from '../KidsCafeProgramAgreement';
import {
  required,
  phone,
  email,
  normalizePhone,
  parseNumber,
  normalizeDate,
  validateDate,
  validTime,
  uniqueKidsCafeTeamMember,
  requiredTrue,
  maxLenth10,
} from '../../helpers';

const ApplicationKidsCafeStepOne = (props) => {
  const {
    submit,
    handleSubmit,
    change,
    readOnly,
    acceptingApplications,
    invalid,
    submitFailed,
    isChildcareCenter,
    childcareLicense,
    interestBreakfast,
    interestBrunch,
    interestLunch,
    interestBogoLunch,
    interestSnack,
    interestSupper,
    participatedAgriculture,
    type,
    enums: {
      termTypes,
      daysForService,
      participantAgeRanges,
      risingStarStatuses,
      childcareLicenseTypes,
    },
  } = props;

  const kidsCafeServiceInterestsByType = kidsCafeServiceInterests.filter((interest) => {
    if (type === 'SCHOOL') {
      return (interest.name !== 'interestBrunch' && interest.name !== 'interestBogoLunch');
    }
    if (type === 'SUMMER') {
      return interest.name !== 'interestSupper';
    }
    return interest;
  });

  const handleTypeChange = (value) => {
    if (value === 'SCHOOL') {
      change('interestBrunch', false);
      change('interestBogoLunch', false);
      change('brunchStartTime', null);
      change('brunchEndTime', null);
      change('bogoLunchStartTime', null);
      change('bogoLunchEndTime', null);
      change('numExpectedParticipantsBrunch', null);
      change('numExpectedParticipantsBogoLunch', null);
    }
    if (value === 'SUMMER') {
      change('interestSupper', false);
      change('supperStartTime', null);
      change('supperEndTime', null);
      change('numExpectedParticipantsSupper', null);
    }
    return value;
  };

  const handleChildcareCenterChange = (value) => {
    if (!value) {
      change('hasMicrowave', null);
      change('isTexasRisingStar', null);
      change('isFederalParticipant', null);
      change('isOpenEnrolledYear', null);
      change('hasInfantsEnrolled', null);
      change('hasAfterSchool', null);
    }
    return value;
  };

  const handleServiceChange = (name, value) => {
    if (!value) {
      if (name === 'interestBreakfast') {
        change('breakfastStartTime', null);
        change('breakfastEndTime', null);
        change('numExpectedParticipantsBreakfast', null);
      }
      if (name === 'interestBrunch') {
        change('brunchStartTime', null);
        change('brunchEndTime', null);
        change('numExpectedParticipantsBrunch', null);
      }
      if (name === 'interestLunch') {
        change('lunchStartTime', null);
        change('lunchEndTime', null);
        change('numExpectedParticipantsLunch', null);
      }
      if (name === 'interestBogoLunch') {
        change('bogoLunchStartTime', null);
        change('bogoLunchEndTime', null);
        change('numExpectedParticipantsBogoLunch', null);
      }
      if (name === 'interestSnack') {
        change('snackStartTime', null);
        change('snackEndTime', null);
        change('numExpectedParticipantsSnack', null);
      }
      if (name === 'interestSupper') {
        change('supperStartTime', null);
        change('supperEndTime', null);
        change('numExpectedParticipantsSupper', null);
      }
    }
    return value;
  };

  return (
    <Form onSubmit={handleSubmit(submit)} id="email-form" className="program-form">
      <div className="site-information">
        <h4 className="purple-headline boxed-in">Site Information</h4>
        <div className="radio-question">
          <label htmlFor="First" className="field-label">Application type:</label>
          {
            !acceptingApplications ? (
              <span style={{ color: 'red' }}>
                We are not currently accepting new applications.
              </span>
            ) : (
              <Field
                name="type"
                options={termTypes.options}
                component={RadioButtonGroup}
                className="radio-field inline-radio w-radio"
                validate={required}
                disabled={readOnly || !acceptingApplications}
                onChange={handleTypeChange}
                normalize={value => handleTypeChange(value)}
              />
            )
          }
        </div>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">Did your site participate in this program last year?</label>
          <Field
            name="new"
            options={[
              {
                value: false,
                label: 'Yes',
              },
              {
                value: true,
                label: 'No',
              },
            ]}
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            parse={val => val === 'true'}
            disabled={readOnly || !acceptingApplications}
            validate={required}
          />
        </div>
        <div className="field-combo-wrapper dropdown-select">
          <div className="field-combo">
            <Query query={allSitesQuery} fetchPolicy="network-only">
              {({ loading, error, data }) => {
                const siteOptions = get(data, 'allSites', []).map(site => ({
                  site,
                  label: site.name,
                }));
                return (
                  <Field
                    label={<label htmlFor="First" className="field-label">Site Name</label>}
                    component={SiteSelectInput}
                    options={siteOptions}
                    validate={required}
                    change={change}
                    loading={loading}
                    name="site.name"
                    placeholder={error ? 'Error loading sites, please refresh.' : 'Enter Site Name'}
                    className="text-field w-input"
                    disabled={readOnly || !acceptingApplications}
                  />
                );
              }}
            </Query>
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last" className="field-label">Closest School</label>}
              component={TextField}
              validate={required}
              name="site.closestSchool"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="First-3" className="field-label">Address Line 1</label>}
              component={TextField}
              validate={required}
              name="site.address1"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Address Line 2 (optional)</label>}
              component={TextField}
              name="site.address2"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Nearest Cross Street</label>}
              component={TextField}
              validate={required}
              name="site.crossStreet"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">City</label>}
              component={TextField}
              validate={required}
              name="site.city"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">County</label>}
              component={TextField}
              validate={required}
              name="site.county"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">State</label>}
              component={TextField}
              validate={required}
              name="site.state"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Zip</label>}
              component={TextField}
              validate={required}
              name="site.zip"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
            />
          </div>
        </div>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">1. Is your site non-profit?</label>
          <Field
            name="forProfit"
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            validate={required}
            parse={val => val === 'true'}
            disabled={readOnly || !acceptingApplications}
          />
        </div>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">2. Has your site participated in the Texas Department of Agriculture CACFP or SFSP meals program?</label>
          <Field
            name="participatedAgriculture"
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            validate={required}
            parse={val => val === 'true'}
            disabled={readOnly || !acceptingApplications}
          />
        </div>
        {participatedAgriculture && <div className="field-combo">
          <Field
            label={<label htmlFor="First-3" className="field-label">Name of Sponsor</label>}
            component={TextField}
            validate={participatedAgriculture && required}
            name="agricultureSponsor"
            className="text-field w-input"
            disabled={readOnly || !acceptingApplications}
          />
        </div>}
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">3. Does your site have a childcare license?</label>
          <Field
            name="childcareLicense"
            options={childcareLicenseTypes.options}
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            validate={required}
            disabled={readOnly || !acceptingApplications}
          />
        </div>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">4. Does your site have access to wifi?</label>
          <Field
            name="hasWifi"
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            validate={required}
            parse={val => val === 'true'}
            disabled={readOnly || !acceptingApplications}
          />
        </div>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">5. Does your site have a device to record meal service (e.g. computer/tablet)?</label>
          <Field
            name="hasRecordingDevice"
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            validate={required}
            parse={val => val === 'true'}
            disabled={readOnly || !acceptingApplications}
          />
        </div>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">6. Do you have storage available that meets Food Safety Guidelines?</label>
          <Field
            name="hasStorage"
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            validate={required}
            parse={val => val === 'true'}
            disabled={readOnly || !acceptingApplications}
          />
        </div>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">7. Does your site receive any truck deliveries? (e.g. FedEx)</label>
          <Field
            name="receivesDeliveries"
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            validate={required}
            parse={val => val === 'true'}
            disabled={readOnly || !acceptingApplications}
          />
        </div>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">8. Is your site a childcare center?</label>
          <Field
            name="isChildcareCenter"
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            validate={required}
            parse={val => val === 'true'}
            disabled={readOnly || !acceptingApplications}
            normalize={value => handleChildcareCenterChange(value)}
          />
        </div>
      </div>
      {isChildcareCenter && (
        <div className="childcare-center-info">
          <h4 className="purple-headline boxed-in">Childcare Center Information</h4>
          <div className="radio-question">
            <label htmlFor="First-3" className="field-label">Do you have a microwave on site?</label>
            <Field
              name="hasMicrowave"
              component={RadioButtonGroup}
              className="radio-field inline-radio w-radio"
              validate={required}
              parse={val => val === 'true'}
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="radio-question">
            <label htmlFor="First-3" className="field-label">Are you a Texas Rising Star Facility?</label>
            <Field
              name="isTexasRisingStar"
              options={risingStarStatuses.options}
              component={RadioButtonGroup}
              className="radio-field inline-radio w-radio"
              validate={required}
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="radio-question">
            <label htmlFor="First-3" className="field-label">Do you currently participate in a federally funded program?</label>
            <Field
              name="isFederalParticipant"
              component={RadioButtonGroup}
              className="radio-field inline-radio w-radio"
              validate={required}
              parse={val => val === 'true'}
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="radio-question">
            <label htmlFor="First-3" className="field-label">Is your site open-enrolled the entire year?</label>
            <Field
              name="isOpenEnrolledYear"
              component={RadioButtonGroup}
              className="radio-field inline-radio w-radio"
              validate={required}
              parse={val => val === 'true'}
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="radio-question">
            <label htmlFor="First-3" className="field-label">Do you have infants enrolled?</label>
            <Field
              name="hasInfantsEnrolled"
              component={RadioButtonGroup}
              className="radio-field inline-radio w-radio"
              validate={required}
              parse={val => val === 'true'}
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="radio-question">
            <label htmlFor="First-3" className="field-label">Does your site have an after-school program available?</label>
            <Field
              name="hasAfterSchool"
              component={RadioButtonGroup}
              className="radio-field inline-radio w-radio"
              validate={required}
              parse={val => val === 'true'}
              disabled={readOnly || !acceptingApplications}
            />
          </div>
        </div>
      )}
      <div className="point-of-contact">
        <h4 className="purple-headline boxed-in">Primary Point of Contact</h4>
        <div className="field-combo-wrapper">
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="First-3" className="field-label">First Name</label>}
              component={TextField}
              validate={required}
              name="site.primaryPointOfContact.firstName"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Last Name</label>}
              component={TextField}
              validate={required}
              name="site.primaryPointOfContact.lastName"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Job Title (optional)</label>}
              component={TextField}
              name="site.primaryPointOfContact.title"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Phone</label>}
              component={TextField}
              validate={[required, phone]}
              normalize={normalizePhone}
              name="site.primaryPointOfContact.phone"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Email</label>}
              component={TextField}
              validate={[required, email, uniqueKidsCafeTeamMember]}
              name="site.primaryPointOfContact.email"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">Is this the site Supervisor?</label>
          <Field
            name="site.primaryPointOfContact.isSupervisor"
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            validate={required}
            parse={val => val === 'true'}
            disabled={readOnly || !acceptingApplications}
          />
        </div>
      </div>
      <div className="point-of-contact">
        <h4 className="purple-headline boxed-in">Secondary Point of Contact</h4>
        <div className="field-combo-wrapper">
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="First-3" className="field-label">First Name</label>}
              component={TextField}
              validate={required}
              name="site.secondaryPointOfContact.firstName"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Last Name</label>}
              component={TextField}
              validate={required}
              name="site.secondaryPointOfContact.lastName"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Job Title (optional)</label>}
              component={TextField}
              name="site.secondaryPointOfContact.title"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Phone</label>}
              component={TextField}
              validate={[required, phone]}
              normalize={normalizePhone}
              name="site.secondaryPointOfContact.phone"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Email</label>}
              component={TextField}
              validate={[required, email, uniqueKidsCafeTeamMember]}
              name="site.secondaryPointOfContact.email"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">Is this the site Supervisor?</label>
          <Field
            name="site.secondaryPointOfContact.isSupervisor"
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            validate={required}
            disabled={readOnly || !acceptingApplications}
            parse={val => val === 'true'}
          />
        </div>
      </div>
      <div className="service">
        <h4 className="purple-headline boxed-in">Site Service Dates and Times</h4>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Start Date</label>}
              component={TextField}
              validate={[required, validateDate]}
              normalize={normalizeDate}
              maxLength={10}
              name="startDate"
              className="text-field w-input"
              placeholder="MM/DD/YYYY"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">End Date</label>}
              component={TextField}
              validate={[required, validateDate]}
              normalize={normalizeDate}
              maxLength={10}
              name="endDate"
              className="text-field w-input"
              placeholder="MM/DD/YYYY"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Site Operating Hours - Start Time</label>}
              component={TextField}
              validate={[required, validTime]}
              maxLength={8}
              name="operatingHoursStartTime"
              className="text-field w-input"
              placeholder="00:00 AM"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Site Operating Hours - End Time</label>}
              component={TextField}
              validate={[required, validTime]}
              maxLength={8}
              name="operatingHoursEndTime"
              className="text-field w-input"
              placeholder="00:00 PM"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Site Gate Code (if applicable)</label>}
              component={TextField}
              validate={maxLenth10}
              maxLength={10}
              name="gateCode"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Total Expected Participants</label>}
              component={TextField}
              validate={required}
              name="numExpectedParticipants"
              className="text-field w-input"
              parse={parseNumber}
              maxLength={4}
              disabled={readOnly || !acceptingApplications}
            />
          </div>
        </div>
        <div className="checklist-question">
          <label htmlFor="First-3" className="field-label">Participants Age Ranges (check all that apply):</label>
          <Field
            name="participantAgeRanges"
            validate={required}
            options={participantAgeRanges.options.filter(el => el.value != 'EIGHTEEN_PLUS')}
            component={CheckboxGroup}
            className="checkbox-inline w-checkbox _12"
            disabled={readOnly || !acceptingApplications}
          />
        </div>
        <div className="checklist-question">
          <label htmlFor="First-3" className="field-label">Days of the week for meal service (check all that apply):</label>
          <Field
            name="daysForService"
            validate={required}
            options={daysForService.options}
            component={CheckboxGroup}
            className="checkbox-inline w-checkbox _16"
            disabled={readOnly || !acceptingApplications}
          />
        </div>
        <div className="checklist-question">
          <label htmlFor="First-3" className="field-label">Select the service(s) you are interested in:</label>
          <div className="checkbox-list">
            {
              kidsCafeServiceInterestsByType.map(serviceInterest => (
                ((serviceInterest.name !== 'interestBreakfast') || (serviceInterest.name === 'interestBreakfast')) &&
                <Field
                  key={serviceInterest.name}
                  name={serviceInterest.name}
                  component={Checkbox}
                  className="w-checkbox checkbox-stacked"
                  label={serviceInterest.label}
                  disabled={readOnly || !acceptingApplications}
                  normalize={(value, prevVal, allVals, prevAllVals, name) => handleServiceChange(name, value)}
                  validate={() => {
                    if (type === 'SCHOOL') {
                      if (!interestBreakfast && !interestLunch && !interestSnack && !interestSupper && serviceInterest.name === 'interestSupper') {
                        return 'Required';
                      }
                    }

                    if (type === 'SUMMER') {
                      if (!interestBreakfast && !interestBrunch && !interestLunch && !interestBogoLunch && !interestSnack && serviceInterest.name === 'interestSnack') {
                        return 'Required';
                      }
                    }

                    return undefined;
                  }}
                />
              ))
            }
          </div>
        </div>
        {interestBreakfast && (
          <div className="field-combo-wrapper">
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Breakfast Service - Start Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="breakfastStartTime"
                className="text-field w-input"
                placeholder="00:00 AM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Breakfast Service - End Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="breakfastEndTime"
                className="text-field w-input"
                placeholder="00:00 PM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Breakfast Service - Expected Participants</label>}
                component={TextField}
                validate={required}
                name="numExpectedParticipantsBreakfast"
                className="text-field w-input"
                parse={parseNumber}
                maxLength={4}
                disabled={readOnly || !acceptingApplications}
              />
            </div>
          </div>
        )}
        {interestBrunch && (
          <div className="field-combo-wrapper">
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Brunch Service - Start Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="brunchStartTime"
                className="text-field w-input"
                placeholder="00:00 AM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Brunch Service - End Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="brunchEndTime"
                className="text-field w-input"
                placeholder="00:00 PM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Brunch Service - Expected Participants</label>}
                component={TextField}
                validate={required}
                name="numExpectedParticipantsBrunch"
                className="text-field w-input"
                parse={parseNumber}
                maxLength={4}
                disabled={readOnly || !acceptingApplications}
              />
            </div>
          </div>
        )}
        {interestLunch && (
          <div className="field-combo-wrapper">
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Lunch Service - Start Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="lunchStartTime"
                className="text-field w-input"
                placeholder="00:00 AM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Lunch Service - End Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="lunchEndTime"
                className="text-field w-input"
                placeholder="00:00 PM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Lunch Service - Expected Participants</label>}
                component={TextField}
                validate={required}
                name="numExpectedParticipantsLunch"
                className="text-field w-input"
                parse={parseNumber}
                maxLength={4}
                disabled={readOnly || !acceptingApplications}
              />
            </div>
          </div>
        )}
        {interestBogoLunch && (
          <div className="field-combo-wrapper">
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">BOGO Lunch Service - Start Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="bogoLunchStartTime"
                className="text-field w-input"
                placeholder="00:00 AM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">BOGO Lunch Service - End Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="bogoLunchEndTime"
                className="text-field w-input"
                placeholder="00:00 PM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">BOGO Lunch Service - Expected Participants</label>}
                component={TextField}
                validate={required}
                name="numExpectedParticipantsBogoLunch"
                className="text-field w-input"
                parse={parseNumber}
                maxLength={4}
                disabled={readOnly || !acceptingApplications}
              />
            </div>
          </div>
        )}
        {interestSnack && (
          <div className="field-combo-wrapper">
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Snack Service - Start Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="snackStartTime"
                className="text-field w-input"
                placeholder="00:00 AM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Snack Service - End Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="snackEndTime"
                className="text-field w-input"
                placeholder="00:00 PM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Snack Service - Expected Participants</label>}
                component={TextField}
                validate={required}
                name="numExpectedParticipantsSnack"
                className="text-field w-input"
                parse={parseNumber}
                maxLength={4}
                disabled={readOnly || !acceptingApplications}
              />
            </div>
          </div>
        )}
        {interestSupper && (
          <div className="field-combo-wrapper">
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Supper Service - Start Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="supperStartTime"
                className="text-field w-input"
                placeholder="00:00 AM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Supper Service - End Time</label>}
                component={TextField}
                validate={[required, validTime]}
                maxLength={8}
                name="supperEndTime"
                className="text-field w-input"
                placeholder="00:00 PM"
                disabled={readOnly || !acceptingApplications}
                autoComplete="input-unsupported"
              />
            </div>
            <div className="field-combo thirds">
              <Field
                label={<label htmlFor="First-3" className="field-label">Supper Service - Expected Participants</label>}
                component={TextField}
                validate={required}
                name="numExpectedParticipantsSupper"
                className="text-field w-input"
                parse={parseNumber}
                maxLength={4}
                disabled={readOnly || !acceptingApplications}
              />
            </div>
          </div>
        )}
        <div className="textarea-combo">
          <div className="field-textarea half">
            <Field
              name="specialDeliveryNotes"
              component={TextArea}
              maxLength={255}
              className="field-textarea w-input"
              label={<label htmlFor="First-3" className="field-label">Special delivery notes (gate code, Door 1, front lobby, etc…)</label>}
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">What is your earliest delivery time?</label>}
              component={TextField}
              validate={[required, validTime]}
              maxLength={8}
              name="earliestDeliveryTime"
              className="text-field w-input"
              placeholder="00:00 AM"
              disabled={readOnly || !acceptingApplications}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
        <div className="textarea-combo">
          <div className="field-textarea half">
            <Field
              name="enrichmentPrograms"
              component={TextArea}
              maxLength={1000}
              className="field-textarea w-input"
              label={<label htmlFor="First-3" className="field-label">What kind of enrichment programs are provided?</label>}
              validate={required}
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="field-textarea half">
            <Field
              name="closureDates"
              component={TextArea}
              maxLength={1000}
              className="field-textarea w-input"
              label={<label htmlFor="First-3" className="field-label">What specific dates are your holiday closures?</label>}
              validate={required}
              disabled={readOnly || !acceptingApplications}
            />
          </div>
        </div>
        <div className="textarea-combo">
          <div className="field-textarea half">
            <Field
              name="fieldTrips"
              component={TextArea}
              maxLength={1000}
              className="field-textarea w-input"
              label={<label htmlFor="First-3" className="field-label">What specific dates are your field trips?</label>}
              validate={required}
              disabled={readOnly || !acceptingApplications}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Who referred you? (optional)</label>}
              component={TextField}
              name="referredBy"
              className="text-field w-input"
              disabled={readOnly || !acceptingApplications}
            />
          </div>
        </div>
      </div>
      <BackgroundVerification {...props} />
      <Field
        component={KidsCafeProgramAgreement}
        name="readProgramAgreement"
        validate={requiredTrue}
      />
      <br />
      <br />
      <>
        <p>
          As a part of the Houston Food Bank (HFB) and the Child and Adult Care
          Food Program (CACFP) and the Summer Food Program (SFSP), I understand
          that the HFB will receive 100% of funds which are reimbursed through
          the Texas Department of Agriculture (TDA).  I recognize any funds the
          HFB receives from the TDA are entirely due to the HFB, as they are utilizing
          reimbursement for meal preparation and overall administration of the CACFP
          and SFSP program. As a result of the program administration by the Houston
          Food Bank, all meal items are provided free of charge.
        </p>
        <br />
        <Field
          name="agreeToReimbursement"
          validate={requiredTrue}
          component={Checkbox}
          className="checkbox-inline w-checkbox"
          label="I certify the above"
          disabled={readOnly || !acceptingApplications}
        />
      </>
      <br />
      <br />
      <>
        <p>
          I also agree and understand if I or my site staff fail to provide
          accurate documentation of meal deliveries, served meals, excessive
          meals and leftover meals causing meals to be disallowed and reimbursement
          lost, site will be subject to the following course of action. This also
          includes, but limited too, not notifying Kids Cafe site of any issues
          at time of delivery, serving emergency meals without approval, allowing
          food to be taken off site without prior approval, failing to report site
          closures resulting in meals being prepared without the ability to serve
          them, and not providing accurate MBIE income forms for child care participants
          resulting in FRL falling below 30% of enrollment.
        </p>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">A.</span>
              <b>First offense</b>
              &nbsp;Written warning of violation requiring acknowledgement
              of Partner Program Director. *
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">B.</span>
              <b>Second offense</b>
              &nbsp;Written second warning of violation given with
              explanation of suspension of meal service if
              violation continues requiring acknowledgement of Partner Program Director. *
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">C.</span>
              <b>Third offense</b>
              &nbsp;Written suspension of meal service requiring
              acknowledgement of Partner Program Director.
              Site will be held fiscally responsible for
              paying the meal cost and transportation fee. *
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">D.</span>
              <b>Fourth offense</b>
              &nbsp;Termination of the meal service program. *
            </p>
          </li>
        </ul>
        <p>
          * If site is held fiscally responsible the site
          will have until the 5th of the succeeding month to
          make the full payment.  Failure to make the full payment
          will result in meal service being suspended until owed amount is paid.
          Penalties for any offenses will be determined on a case by case
          basis and may include termination of meal service/program at any time.
          This Agreement may be terminated at will by either party with written
          notice delivered to either party not less than 30 days prior
          to the desired date. Upon termination of this agreement, the
          Program Partner will return any equipment and/or materials provided
          by the Houston Food Bank for the Kids Cafe program to HFB within
          30 days of termination date.
        </p>
        <br />
        <Field
          name="agreeToDocumentation"
          validate={requiredTrue}
          component={Checkbox}
          className="checkbox-inline w-checkbox"
          label="I certify the above"
          disabled={readOnly || !acceptingApplications}
        />
      </>
      <ApplicationActions {...props} />
      {
        (invalid && submitFailed) && (
          <span
            style={{
              color: 'red',
            }}
          >
            You have required field(s) that are missing. Please see above.
          </span>
        )
      }
    </Form>
  );
};

ApplicationKidsCafeStepOne.propTypes = {
  submit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  isChildcareCenter: PropTypes.bool,
  childcareLicense: PropTypes.bool,
  participatedAgriculture: PropTypes.bool,
  interestBreakfast: PropTypes.bool,
  interestBrunch: PropTypes.bool,
  interestLunch: PropTypes.bool,
  interestBogoLunch: PropTypes.bool,
  interestSnack: PropTypes.bool,
  interestSupper: PropTypes.bool,
  change: PropTypes.func.isRequired,
  enums: PropTypes.object.isRequired,
  acceptingApplications: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
};

ApplicationKidsCafeStepOne.defaultProps = {
  readOnly: false,
  type: null,
  isChildcareCenter: false,
  childcareLicense: false,
  participatedAgriculture: false,
  interestBreakfast: false,
  interestBrunch: false,
  interestLunch: false,
  interestBogoLunch: false,
  interestSnack: false,
  interestSupper: false,
};

export default ApplicationKidsCafeStepOne;
