import gql from 'graphql-tag';

export default gql`
  mutation saveApplicationStepOne($input: ApplicationStepOneInput!) {
    saveApplicationStepOne(input: $input) {
      applicationBackpackBuddyId
      new
      organizationType
      nonProfitOrganizations
      agreeToProgramPurpose
      agreeToProgramResponsibilities
      agreeNondiscrimination
      school {
        name
        type
        districtId
        address1
        address2
        city
        county
        state
        zip
        gradesServed
        teamMembersBackpackBuddy {
          teamMemberId
          firstName
          lastName
        }
      }
      emailSecondaryCoordinator
    }
  }
`;
