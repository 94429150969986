import gql from 'graphql-tag';

export default gql`
  query sites {
    sites {
      siteId
      name
      address1
      address2
      crossStreet
      city
      state
      zip
      county
      closestSchool
      statusKidsCafe
      deletedAt
      siteMembers {
        siteMemberId
        fullName
        email
        phone
        type
      }
      applicationsKidsCafe {
        applicationKidsCafeId
        new
        submittedAt
        status
        applicationYear
        agreeToReimbursement
        agreeToDocumentation
        certififedVerification
        type
        forProfit
        participatedAgriculture
        agricultureSponsor
        childcareLicense
        hasWifi
        hasRecordingDevice
        hasStorage
        receivesDeliveries
        isChildcareCenter
        hasMicrowave
        isTexasRisingStar
        isFederalParticipant
        isOpenEnrolledYear
        hasInfantsEnrolled
        hasAfterSchool
        specialDeliveryNotes
        earliestDeliveryTime
        fieldTrips
        startDate
        endDate
        operatingHoursStartTime
        operatingHoursEndTime
        gateCode
        numExpectedParticipants
        numExpectedParticipantsBreakfast
        numExpectedParticipantsBrunch
        numExpectedParticipantsLunch
        numExpectedParticipantsBogoLunch
        numExpectedParticipantsSnack
        numExpectedParticipantsSupper
        participantAgeRanges
        daysForService
        interestBreakfast
        interestBrunch
        interestLunch
        interestBogoLunch
        interestSnack
        interestSupper
        breakfastStartTime
        breakfastEndTime
        brunchStartTime
        brunchEndTime
        lunchStartTime
        lunchEndTime
        bogoLunchStartTime
        bogoLunchEndTime
        snackStartTime
        snackEndTime
        supperStartTime
        supperEndTime
        enrichmentPrograms
        closureDates
        referredBy
        createdAt
      }
    }
  }
`;
