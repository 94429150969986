import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';
import { graphql, compose } from 'react-apollo';
import { get, indexOf } from 'lodash';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import Loading from '../Loading';
import ApplicationTeachersAidStepOne from './ApplicationTeachersAidStepOne';
import ApplicationTeachersAidStepTwo from './ApplicationTeachersAidStepTwo';
import ApplicationTeachersAidStepThree from './ApplicationTeachersAidStepThree';
import ApplicationStatusPending from '../ApplicationStatusPending';
import ProgressBar from '../ProgressBar';
import withProgramsLayout from '../../helpers/withProgramsLayout';
import removeTypename from '../../helpers/removeTypename';
import getErrorMessage from '../../helpers/getErrorMessage';
import { setUserAction } from '../../redux';
import {
  educatorUserQuery,
  configTeachersAidQuery,
  saveTeachersAidApplicationStepOneMutation,
  saveTeachersAidApplicationStepTwoMutation,
  saveTeachersAidApplicationStepThreeMutation,
  applicationTeachersAidQuery,
  programTermsQuery,
  userApplicationsTeachersAidQuery,
} from '../../apollo';
import { DEFAULT_TERM_YEAR } from '../../config';

class ApplicationTeachersAid extends Component {
  steps = {
    '/teachers-aid/application/step-1': {
      step: 1,
      component: ApplicationTeachersAidStepOne,
    },
    '/teachers-aid/application/step-2': {
      step: 2,
      component: ApplicationTeachersAidStepTwo,
    },
    '/teachers-aid/application/step-3': {
      step: 3,
      component: ApplicationTeachersAidStepThree,
    },
    '/teachers-aid/application/status': {
      step: 4,
      component: ApplicationStatusPending,
    },
  }

  getNextStep = (applicationTeachersAidId) => {
    const { match } = this.props;
    const applicationSteps = Object.keys(this.steps);
    const currentIndex = indexOf(applicationSteps, `/teachers-aid/application/${match.params.step}`);
    const stepsToCheck = applicationSteps.slice(currentIndex, applicationSteps.length + 1);
    let nextStep = stepsToCheck.find(step => (
      step !== `/teachers-aid/application/${match.params.step}`
    ));
    if (applicationTeachersAidId) {
      nextStep = `${nextStep}/${applicationTeachersAidId}`;
    }
    return nextStep;
  }

  saveApplication = async ({
    draft,
    applicationTeachersAidId,
    school,
    new: isNew,
    termId,
    numFreeLunch,
    numReducedLunch,
    numTotalEnrollment,
    demographicAsian,
    organizationType,
    nonProfitOrganizations,
    demographicBlack,
    demographicHispanic,
    demographicWhite,
    certifiedProgramRules,
    certifiedAgreement,
    certifiedNonDiscrimination,
    certififedVerification,
    nameSignFirst,
    nameSignSecond,
    nameSignThird
  }) => {
    const {
      dispatch,
      client: { mutate, query },
      match,
    } = this.props;

    const { step } = this.steps[`/teachers-aid/application/${match.params.step}`];
    let mutation;
    let response;
    let variables;

    switch (step) {
      case 1:
        mutation = saveTeachersAidApplicationStepOneMutation;
        response = 'saveTeachersAidApplicationStepOne';
        variables = {
          draft,
          applicationTeachersAidId,
          new: isNew,
          termId,
          school: {
            id: school.id,
            schoolId: school.schoolId,
            name: school.name,
            gradesServed: school.gradesServed,
            address1: school.address1,
            address2: school.address2,
            city: school.city,
            state: school.state,
            zip: school.zip,
            county: school.county,
            districtId: school.districtId,
            type: school.type,
            teachersAidSchoolNumber: school.teachersAidSchoolNumber,
            siteCoordinator: {
              ...school.siteCoordinator,
              type: 'SITE_COORDINATOR',
            },
            principal: {
              ...school.principal,
              type: 'PRINCIPAL',
            },
          },
          numFreeLunch,
          numReducedLunch,
          numTotalEnrollment,
          demographicAsian,
          organizationType,
          nonProfitOrganizations,
          demographicBlack,
          demographicHispanic,
          demographicWhite,
        };
        break;
      case 2:
        mutation = saveTeachersAidApplicationStepTwoMutation;
        response = 'saveTeachersAidApplicationStepTwo';
        variables = {
          draft,
          applicationTeachersAidId,
          certififedVerification,
          school: {
            id: school.id,
            schoolId: school.schoolId,
            teachers: school.teachers,
          },
        };
        break;
      case 3:
        mutation = saveTeachersAidApplicationStepThreeMutation;
        response = 'saveTeachersAidApplicationStepThree';
        variables = {
          draft,
          applicationTeachersAidId,
          certifiedProgramRules,
          certifiedAgreement,
          certifiedNonDiscrimination,
          nameSignFirst,
          nameSignSecond,
          nameSignThird
        };
        break;
      default:
        break;
    }

    try {
      const { data } = await mutate({
        mutation,
        variables: { input: { ...removeTypename(variables) } },
        refetchQueries: [{
          query: userApplicationsTeachersAidQuery,
        }],
      });

      const { data: { educatorUser } } = await query({
        query: educatorUserQuery,
        fetchPolicy: 'network-only',
      });

      if (data[response] && educatorUser) {
        if (!draft) {
          dispatch(setUserAction(educatorUser));
          dispatch(push(this.getNextStep(data[response].applicationTeachersAidId)));
          toast('Save Successful');
        } else {
          dispatch(setUserAction(educatorUser));
          dispatch(push('/'));
          toast('Draft Saved');
        }
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage) toast(errorMessage);
      throw (error);
    }
  };

  saveAsDraft = async (values) => {
    try {
      await this.saveApplication({
        draft: true,
        ...values,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage) toast(errorMessage);
      throw (error);
    }
  };

  render() {
    const {
      match,
      location,
      data,
      configTeachersAid,
      programTermsData,
      user,
      adminView,
      program: {
        name,
        programUrl,
      },
    } = this.props;

    if (
      (data && data.loading)
      || configTeachersAid.loading
      || programTermsData.loading
    ) return <Loading />;

    //
    // Teachers Aid Application Redirect Logic
    //
    if (adminView) {
      if (
        (data && data.error)
        || (data && !data.applicationTeachersAid)
        || configTeachersAid.error
      ) {
        return <Redirect to={`${programUrl}/schools`} />;
      }
      if (
        (data && data.applicationTeachersAid && match.params.step === 'status')
      ) {
        return <Redirect to={`${programUrl}/schools`} />;
      }
    } else {
      // If error loading application or application config re-route user
      if (
        (data && data.error)
        || (data && !data.applicationTeachersAid)
        || configTeachersAid.error
      ) {
        return <Redirect to="/login" />;
      }
      // If attempt to view further step before there is data, route to first step
      if (match.params.step && match.params.step !== 'step-1' && !data) {
        return <Redirect to={`${programUrl}/application/step-1`} />;
      }
      // If no associated application route to the start page
      if (
        user.applicationTeachersAidRequired
        && !user.applicationTeachersAidRequiredId
        && !match.params.step
        && !data
      ) {
        return <Redirect to={`${programUrl}/start`} />;
      }
      // If required to fill out application route to the first step
      if (
        user.applicationTeachersAidRequired
        && user.applicationTeachersAidRequiredId
        && !data
      ) {
        return <Redirect to={`${programUrl}/application/step-1/${user.applicationTeachersAidRequiredId}`} />;
      }
      // If attempt to view an input step of the application while
      // it's pending approval, route to the status page
      if (
        (data
          && data.applicationTeachersAid
          && data.applicationTeachersAid.status === 'PENDING_PRINCIPAL'
          && match.params.step !== 'status')
      ) {
        return <Redirect to={`${programUrl}/application/status/${match.params.id}`} />;
      }
      // If attempt to view the status step of the application while
      // it's in progress, route to the first step
      if (
        (data
          && data.applicationTeachersAid
          && data.applicationTeachersAid.status === 'IN_PROGRESS'
          && match.params.step === 'status')
      ) {
        return <Redirect to={`${programUrl}/application/step-1/${user.applicationTeachersAidRequiredId}`} />;
      }
    }

    const activeStep = get(this.steps, [`${programUrl}/application/${match.params.step}`]);

    if (!activeStep) return <Redirect to="/login" />;
    const { component: ApplicationStep, step } = activeStep;
    const { schoolEnabled, summerEnabled } = configTeachersAid.configTeachersAid;
    const params = new URLSearchParams(location.search);
    let termYear = params.get('termYear') || DEFAULT_TERM_YEAR;
    if (data && data.applicationTeachersAid && data.applicationTeachersAid.term) {
      termYear = data.applicationTeachersAid.term.year;
    }

    const termsForYear = programTermsData.programTerms.filter(({ year }) => year === termYear);
    const termsAvailable = (configTeachersAid && termsForYear && Boolean(termsForYear.length) && (schoolEnabled || summerEnabled));
    const readOnly = (data && data.applicationTeachersAid && (data.applicationTeachersAid.status === 'APPROVED')) || adminView || !termsAvailable;
    const appTermYear = get(data, 'applicationTeachersAid.term.year', termYear);

    return (
      <Fragment>
        <section className={classnames({ 'section-content': activeStep.step !== 4 }, { 'section-begin-application': activeStep.step === 4 })}>
          <div className="_1300-container">
            <div className={classnames({ 'program-form-block w-form': activeStep.step !== 4 }, { 'start-flex': activeStep.step === 4 })}>
              <ProgressBar step={step} programName={name} termYear={appTermYear} />
              <ApplicationStep
                {...this.props}
                save={this.saveApplication}
                saveAsDraft={this.saveAsDraft}
                readOnly={readOnly}
                termsAvailable={termsAvailable}
                termsForYear={termsForYear}
                step={step}
              />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('applicationForm');
  const currentApplication = get(ownProps.data, 'applicationTeachersAid', {
    school: {
      teachers: [],
    },
  });
  return {
    adminView: state.user.type === 'ADMIN',
    newTeacher: selector(state, 'newTeacher'),
    existingTeachers: selector(state, 'school.teachers'),
    formSyncErrors: getFormSyncErrors('applicationForm')(state),
    user: state.user,
    initialValues: {
      ...currentApplication,
    },
  };
};

const withData = ComponentToWrap => (
  (otherProps) => {
    const WithData = compose(
      graphql(applicationTeachersAidQuery, {
        name: 'data',
        skip: ({ match }) => !match.params.id,
        options: ({ match }) => ({
          variables: {
            applicationTeachersAidId: match.params.id,
          },
          fetchPolicy: 'network-only',
        }),
      }),
      graphql(configTeachersAidQuery, {
        name: 'configTeachersAid',
      }),
      graphql(programTermsQuery, {
        name: 'programTermsData',
        options: ({ program: { name } }) => ({
          variables: {
            programName: name,
          },
          fetchPolicy: 'network-only',
        }),
      }),
      connect(mapStateToProps),
      reduxForm({
        form: 'applicationForm',
        enableReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
      }),
    )(ComponentToWrap);

    return <WithData {...otherProps} />;
  }
);

ApplicationTeachersAid.propTypes = {
  adminView: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  configTeachersAid: PropTypes.object.isRequired,
  data: PropTypes.object,
  programTermsData: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};

ApplicationTeachersAid.defaultProps = {
  data: null,
};

export default withProgramsLayout(withData(ApplicationTeachersAid));
