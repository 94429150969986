/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  required,
  requiredTrue,
  email,
  signeeEmailmatch,
} from '../../helpers';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import SchoolMarketAgreement from '../SchoolMarketAgreement';
import GeneralAgreement from '../GeneralAgreement';
import NonDiscriminationStament from '../NonDiscriminationStament';

const SchoolMarketSignatureContent = ({
  isAdmin,
  school,
  signee,
  isApproved,
  application,
  isHISD,
}) => {
  const signatureTitle = isHISD ? 'Acknowledgement' : 'Agreement';
  const { marketType } = application;

  return (
    <>
      <div className="bb-title">
        <h2 className="purple-headline half-width signature">
          <strong>{`School Market Program ${signatureTitle}`}</strong>
        </h2>
        <div className="agreement-site-info">
          <div className="site-info-line">
            <div className="site-info">School Name:</div>
            <div className="site-info-input">{school.name}</div>
          </div>
          <div className="site-info-line">
            <div className="site-info">School District:</div>
            <div className="site-info-input">{school.districtName}</div>
          </div>
          <div className="site-info-line">
            <div className="site-info">Address Line 1:</div>
            <div className="site-info-input">{school.address1}</div>
          </div>
          <div className="site-info-line">
            <div className="site-info">Address Line 2:</div>
            <div className="site-info-input">{school.address2}</div>
          </div>
          <div className="site-info-line">
            <div className="site-info">City:</div>
            <div className="site-info-input">{school.city}</div>
          </div>
          <div className="site-info-line">
            <div className="site-info">County:</div>
            <div className="site-info-input">{school.county}</div>
          </div>
          <div className="site-info-line">
            <div className="site-info">State:</div>
            <div className="site-info-input">{school.state}</div>
          </div>
          <div className="site-info-line">
            <div className="site-info">Postal Code:</div>
            <div className="site-info-input">{school.zip}</div>
          </div>
          <div className="site-info-line">
            <div className="site-info">Agency Number:</div>
            <div className="site-info-input">{school.schoolMarketAgencyNumber}</div>
          </div>
        </div>
      </div>
      <GeneralAgreement data={application} />
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            label={
                (
                  <Fragment>
                    <label htmlFor="Last" className="field-label">
                      Agreement Sign
                    </label>
                    <i>{application.educator.firstName} {application.educator.lastName}</i>
                  </Fragment>
                )
              }
            component={TextField}
            placeholder="Enter Your Name"
            validate={[required]}
            disabled={true}
            name="nameSignFirst"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo">
          <Field
            label={<strong>I Agree</strong>}
            name="agreeToProgramPurpose"
            validate={requiredTrue}
            component={Checkbox}
            className="checkbox-inline"
            disabled={true}
            {...{
              style: {paddingBottom: 35}
            }}
          />
        </div>
      </div>
      <NonDiscriminationStament data={application} />
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            label={
                (
                  <Fragment>
                    <label htmlFor="Last" className="field-label">
                      Nondiscrimination Sign
                    </label>
                    <i>{application.educator.firstName} {application.educator.lastName}</i>
                  </Fragment>
                )
              }
            component={TextField}
            placeholder="Enter Your Name"
            validate={[required]}
            disabled={true}
            name="nameSignSecond"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo">
          <Field
            label={<strong>I Agree</strong>}
            name="agreeNondiscrimination"
            validate={requiredTrue}
            component={Checkbox}
            className="checkbox-inline"
            disabled={true}
            {...{
              style: {paddingBottom: 35}
            }}
          />
        </div>
      </div>
      <SchoolMarketAgreement />
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            label={
                (
                  <Fragment>
                    <label htmlFor="Last" className="field-label">
                      Program Sign
                    </label>
                    <i>{application.educator.firstName} {application.educator.lastName}</i>
                  </Fragment>
                )
              }
            component={TextField}
            placeholder="Enter Your Name"
            validate={[required]}
            disabled={true}
            name="nameSignThird"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo">
          <Field
            label={<strong>I Agree</strong>}
            name="agreeToProgramResponsibilities"
            validate={requiredTrue}
            component={Checkbox}
            className="checkbox-inline"
            disabled={true}
            {...{
              style: {paddingBottom: 35}
            }}
          />
        </div>
      </div>
      <p>
      Type your name and email as your acknowledgement.
      </p>
      <div className="field-combo-wrapper">
        <div className="field-combo thirds">
          <Field
            label={
              (
                <Fragment>
                  <label htmlFor="First-3" className="field-label">
                    {isHISD ? 'Primary Point of Contact Name' : 'Principal Name'}
                  </label>
                  <i>{signee.fullName}</i>
                </Fragment>
              )
            }
            component={TextField}
            validate={required}
            disabled={isApproved || isAdmin}
            name="signeeName"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo thirds">
          <Field
            label={
              (
                <Fragment>
                  <label htmlFor="First-3" className="field-label">
                    {isHISD ? 'Primary Point of Contact Email' : 'Principal Email'}
                  </label>
                  <i>{signee.email}</i>
                </Fragment>
              )
            }
            component={TextField}
            validate={[required, email, signeeEmailmatch]}
            disabled={isApproved || isAdmin}
            name="signeeEmail"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo thirds">
          <Field
            label={<label htmlFor="First-3" className="field-label">Date</label>}
            component={TextField}
            disabled
            name="signedAt"
            className="text-field w-input"
          />
        </div>
      </div>
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <div style={{ position: 'relative' }}>
            <label htmlFor="First-3" className="field-label">HFB Signature (Senior Outreach Logistic Manager)</label>
            <input disabled type="text" className="signature-placeholder" />
          </div>
        </div>
        <div className="field-combo">
          <div style={{ position: 'relative' }}>
            <label htmlFor="First-3" className="field-label">Date</label>
            <input disabled type="text" className="signature-placeholder" />
          </div>
        </div>
      </div>
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <div style={{ position: 'relative' }}>
            <label htmlFor="First-3" className="field-label">HFB Signature (HFB President and CEO)</label>
            <input disabled type="text" className="signature-placeholder" />
          </div>
        </div>
        <div className="field-combo">
          <div style={{ position: 'relative' }}>
            <label htmlFor="First-3" className="field-label">Date</label>
            <input disabled type="text" className="signature-placeholder" />
          </div>
        </div>
      </div>
    </>
  );
};

SchoolMarketSignatureContent.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  school: PropTypes.object.isRequired,
  signee: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  isHISD: PropTypes.bool.isRequired,
};

export default SchoolMarketSignatureContent;
